<template>
  <div class="user-detail">
    <app-detail :opt="opt" ref="detail" v-loading="isLoading"></app-detail>
  </div>
</template>
<script>
let Vue;
let type = '';
let usernameVali = (rule, value, callback) => {
  // var reg = /(?=.{6,18})(?=.*)(?=.*[a-z])[\x20-\x7f]*/i;
  var reg = /^[a-zA-Z0-9]{6,16}$/;
  // if (value && value.length < 6) {
  //   callback(new Error("用户名不能小于6位字符"));
  // }
  // if (value && value.length > 16) {
  //   callback(new Error("用户名不能大于16位字符"));
  // }
  if (!reg.test(value)) {
    callback(
      new Error("用户名只能包含大小写字母与数字,长度6-16位")
    );
  }
  if (value && type == 'add') {
    Vue.post("user-service/user/checkUserName", {
      username: value
    })
      .then(function (res) {
        if (res.exist) {
          callback(new Error("该账号已被注册"));
        } else {
          callback();
        }
      })
      .catch(function () {
        new Error("网络异常");
      });
  } else {
    callback();
  }
};

let passwordVali = (rule, value, callback) => {
  if (value == "Aa1*******") {
    callback();
  }
  var reg = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
  var reg2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
  if (reg.test(value)) {
    callback(new Error("密码只能包含大小写字母、数字、英文符号"));
  }
  if (!reg2.test(value)) {
    callback(new Error("密码必须最少包含字母、数字、1个大写字母、1个小写字母,长度8-16位"));
  } else {
    // var arr = ["", "低", "中", "高"]; // 获取对象
    //   var s = 0;
    //   if (/[a-zA-Z]/.test(value)) {
    //     s++;
    //   }
    //   if (/[0-9]/.test(value)) {
    //     s++;
    //   }
    //   if (/[^0-9a-zA-Z]/.test(value)) {
    //     s++;
    //   }
    // callback(arr[s]);
    callback();
  }
};
let validMobile = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback(new Error("请输入手机号码"));
  } else {
    let reg = /^1[3-9](\d){9}$/;
    if (!reg.test(value)) {
      callback(new Error("手机号码格式不对"));
    } else {
      callback();
    }
  }
};
let validName = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback(new Error("请输入姓名"));
  } else {
    //姓名支持输入中英文字母，汉字，1~16个字符
    let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
    if (!reg.test(value)) {
      callback(new Error("姓名格式不对"));
    } else {
      callback();
    }
  }
};
import cabinetUtil from '../../utils/cabinetUtil.js'
export default {
  data() {
    let _this = this;
    Vue = this;
    return {
      isLoading: false,
      disabledFlag: false,
      allFlag: false, //返显是否全选 true为全选
      userId: '',
      opt: {
        title: "用户详情",
        form: [{
          label: "账号",
          key: "username",
          rules: [{
            required: true,
            validator: usernameVali,
            trigger: "blur"
          }]
        },
        {
          label: "密码",
          key: "password",
          type: "password",
          rules: [{
            required: true,
            validator: passwordVali,
            trigger: "blur"
          }
            // { required: true }
          ]
        },
        {
          label: "姓名",
          key: "realname",
          rules: [{
            required: true,
            validator: validName,
            trigger: "blur"

          }]
        },
        // {
        //   label: "手机号",
        //   key: "workNo",
        //   maxlength:'11',
        //   filters: {
        //     key: "roleIds",
        //     notShow: true,
        //     value: "8"
        //   },
        //   rules: [{
        //     required: true,
        //     validator: validMobile,
        //       trigger: "blur"
        //   }]
        // },
        {
          label: "角色",
          key: "roleIds",
          type: "select",
          rules: [{
            required: true
          }],
          opt: {
            list: [],
            change(val) {
              if(val == '9') {
                // 选择报警器角色时 请求报警器列表数据
                _this.getAlarmList();
              }
            }
          }
        },
        {
          label: "授权房源",
          type: 'select-cascader',
          value: [],
          key: "selectedRoom",
          filters: {
            key: "roleIds",
            value: "3"
          },
          opt: {
            props: {
              oneLoad: _this.oneLoad,
              twoLoad: _this.twoLoad,
              userId: _this.$route.params.id,
              querySearchAsync: _this.querySearchAsync,
              getAllFlag: _this.getAllFlag
            }
          }
        },
        {
          label: "授权房源",
          key: "apartmentIds",
          type: "select-filterables",
          maxHeight: 'maxHeight400',
          filters: {
            key: "roleIds",
            value: "8"
          },
          // rules: [{ required: true }],
          opt: {
            list: [],
            change(arr, cb, form) {
              if (form && form.hasOwnProperty("apartmentIds")) {
                if (arr.length > 0 && arr[arr.length - 1] == 0) {
                  form["apartmentIds"] = ["0"];
                } else if (arr.length > 1 && arr[0] == 0) {
                  arr.shift();
                  form["apartmentIds"] = arr;
                } else if (arr[0] == "未授权房源") {
                  arr.shift();
                  form["apartmentIds"] = arr;
                }
              } else {
                console.warn("属性不存在：fapartmentIds");
              }
            }
          }
        },
        {
          label: "手机号码",
          key: "workNo",
          maxlength: '11',
          rules: [{
            required: true,
            validator: validMobile,
            trigger: "blur"
          }]
        },
        {
          label: "关联报警器",
          key: "alarmMacs",
          type: "select-filterables",
          maxHeight: 'maxHeight400',
          filters: {
            key: "roleIds",
            value: "9"
          },
          rules: [{ required: true }],
          opt: {
            list: [],
          }
        },
        {
          label: "备注",
          key: "remarks",
          type: "textarea",
          maxlength: 120,
          filters: {
            key: "roleIds",
            value: "9"
          },
        },
        {
          label: "使用单位",
          key: "endUser",
          filters: {
            key: "roleIds",
            value: "8"
          },
        },{
          label: "网关管理",
          key: "gatewayPermit",
          type: "switch",
          opt: {
            activeText: "允许",
            inactiveText: "禁止",
          },
          filters: {
            key: "roleIds",
            value: "3"
          },
          subLabel: '允许当前工程软件绑定、解绑、查看蓝牙网关',
        },
        {
          label: "授权网关",
          type: 'select-cascader-gateway',
          value: [],
          key: "selectedGateways",
          filters: [{
            key: "roleIds",
            value: "3"
          },{
            key: "gatewayPermit",
            value: "1"
          }],
          opt: {
            props: {
              queryHouses: _this.queryHouses,
              queryGateways: _this.queryGateways,
            }
          }
        },
        {
          label: "绑定货柜",
          key: "isCounter",
          type: "switch",
          filters: {
            key: "roleIds",
            value: "3"
          },
          subLabel: "启用后的工程人员登录账号后支持扫码绑定货柜设备",
        },
        {
          label: "授权货柜",
          type: 'selectStores',
          value: '',
          key: "selectData",
          filters: [{
            key: "roleIds",
            value: "3"
          },{
            key: "isCounter",
            value: "1"
          }],
          opt: {
            props: {
              queryStores: _this.queryStores,
              queryAllStores: _this.queryAllStores
            }
          }
        },
        // {
        //   label: "授权货柜2",
        //   type: 'select-cascader-stores',
        //   value:[],
        //   rules: [{ required: true }],
        //   key: "authorize2",
        //   filters: {
        //     key: "roleIds",
        //     value: "3"
        //   },
        //   opt: {
        //     props: {
        //     }
        //   }
        // },
        {
          label: "解绑货柜",
          key: "unBindCounter",
          type: "switch",
          opt: {
            activeText: "允许",
            inactiveText: "禁止"
          },
          filters: [{
            key: "roleIds",
            value: "3"
          },{
            key: "isCounter",
            value: "1"
          }],
        },
        {
          label: "工程对接",
          key: "projectPermit",
          type: "switch",
          rules: [{
            required: true
          }],
          opt: {
            activeText: "开启",
            inactiveText: "关闭"
          },
          filters: [{
            key: "roleIds",
            value: "3"
          }]
        },
        // {
        //   label: "授权应用",
        //   key: "authorizedApp",
        //   type: "select",
        //   rules: [{
        //     required: true
        //   }],
        //   opt: {
        //     list: [{
        //       value: 1,
        //       label: "网约房对接"
        //     }]
        //   },
        //   filters: [{
        //     key: "roleIds",
        //     value: "3"
        //   },{
        //     key: "projectPermit",
        //     value: "1"
        //   }]
        // },
        {
          label: "账号状态",
          key: "userstate",
          type: "switch"
        }

        ],
        buttons: [{
          name: "提交",
          show: ["add", "edit"],
          cb(form) {
            // 若选择了全部，只提交空
            // return;
            let dtoApartmentIds = [];
            if (form.roleIds == '8') {
              if (form.apartmentIds && form.apartmentIds.indexOf("0") != -1) {
                dtoApartmentIds = [];
              } else if (form.apartmentIds && (form.apartmentIds.length == 0 || form.apartmentIds[0] ==
                "未授权房源")) {

                dtoApartmentIds = [{
                  apartmentId: "1",
                  apartmentBuildingItemList: null
                }];;
              } else {
                form.apartmentIds.forEach(item => {
                  let apartmentIdData = {
                    apartmentId: item,
                    apartmentBuildingItemList: null
                  };
                  dtoApartmentIds.push(apartmentIdData);
                })

                // dtoApartmentIds = form.apartmentIds ?
                //   JSON.parse(JSON.stringify(form.apartmentIds)) :
                //   null;
              }
            }
            if ((form.selectedRoom && form.selectedRoom.length == 0 && form.roleIds == '3') || !form.selectedRoom)//空
            {
              form.selectedRoom = [{
                apartmentId: "1",
                apartmentBuildingItemList: null
              }];
            }
            else if (form.selectedRoom && form.selectedRoom.length == 1 && form.selectedRoom[0] == '0')//全选
            {
              form.selectedRoom = [];
            }
            _this.addOrUpdateSystemUser(form, dtoApartmentIds)
          }
        }],
        get(opt) {
          let dto = {
            userId: opt.id
          };
          _this.post("/user-service/user/getSystemUser", dto).then(data => {
            // 保存原始账号
            data.userstate = data.userstate == 2 ? true : false;
            data.isCounter = data.isCounter == 1 ? true : false;
            data.gatewayPermit =  data.userProps ? (data.userProps.gatewayPermit == 1 ? true : false) : false;
            // let tempArr = [];
            // if (data.userApartmentList) {
            //   data.userApartmentList.forEach((item, index) => {
            //     tempArr.push(item['apartmentId']);
            //   });
            // }

            // if (tempArr && tempArr.length == 0) {
            //   data.apartmentIds = ["未授权房源"];
            // } else {
            //   data.apartmentIds = tempArr.length ? tempArr : ["0"];
            // }
            data.roleIds = data.roleIds[0];
            data.password = "Aa1*******";
            // if (data.roleIds == '8') {
            //   data.phonenum2 = data.phonenum;
            // }
            _this.oldPassword = data.password;
            _this.opt.form[13].value = data.userId
            _this.allFlag = false;
            if (!data.userApartmentList) //全部为null
            {
              _this.allFlag = true;
            }
            data.alarmMacs = data.l2dAlarmMacList;
            Promise.all([_this.getRole(), _this.getApartmentList(data.roleIds),_this.getSelected()]).then(
              (res) => {
                if (!data.userApartmentList) //全部
                {
                  data.apartmentIds = ["0"];
                } else if (data.userApartmentList.length == 0) {
                  data.apartmentIds = ["未授权房源"];
                } else {
                  data.apartmentIds = _this.apartmentIdsArr;
                }
                data.unBindCounter = data.relieveBindCounter == 1 ? true : false;
                data.projectPermit = data.projectPermit == 1 ? true : false;

                let selectData = []
                let submitSelectData = []
                if(_this.cache.get("selectedData")){
                    _this.cache.get("selectedData").forEach(item => {
                      selectData.push(item.counterName)
                      submitSelectData.push({counterId:item.counterId,shopId:item.groupId})
                    })
                }else{
                  if(res[2]){
                    res[2].forEach(item => {
                      selectData.push(item.counterName)
                      submitSelectData.push({counterId:item.counterId,shopId:item.groupId})
                    })
                  }
                }
                data.selectData = selectData.join('、')
                data.submitSelectData = submitSelectData
                opt.cb(data);
              }
            );
          });
        }
      },
      userApartmentList: [],
    };
  },
  activated() {
    console.log("user-detail created!!");
    type = this.$route.params.type;
    if (this.$route.params.type == "add") {
      this.getRole();
      this.getApartmentList();
      this.userId = '';
      this.opt.form[0].disabled = false;
    } else {
      this.opt.form[0].disabled = true;
      this.userId = this.$route.params.id;
    }
    this.disabledFlag = this.$route.params.type == "show" ? true : false;
    this.$refs.detail.title = ["添加用户", "编辑用户", "用户详情"][{
      add: 0,
      edit: 1,
      show: 2
    }[this.$route.params.type]];
  },
  beforeRouteLeave(to, from, next) {
    if(to.path !== '/main/user/authorized'){
      this.cache.del('selectedData')
    }
    next();
  },
  methods: {
    getAllFlag(callback) {
      callback(this.allFlag)
    },
    oneLoad() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999"
      };
      return new Promise((resolve, reject) => {
        this.post("landlord-service/apartment/queryApartmentInfo", dto).then(
          res => {
            resolve(res)
          }).catch(() => {
            reject();
          })
      })
    },
    twoLoad(params) {
      let idx = 0;
      let _this = this;
      return new Promise((resolve, reject) => {
        if (params.firstFlag)//全选时第一次返显
        {
          function postInfo(dto) {
            _this.post("landlord-service/room/querySelectedHomeInfo", dto).then(
              res => {
                if (res && res.length == 0) {
                  idx++;
                  postInfo({ apartmentId: params.apartmentIds[idx].id, type: 'all' });
                }
                else {
                  resolve(res)
                }
              })
          }
          postInfo({ apartmentId: params.apartmentIds[idx].id, type: 'all' })
        }
        else {
          this.post("landlord-service/room/querySelectedHomeInfo", params).then(
            res => {
              resolve(res)
            })
        }

      })
    },
    //queryString 为在框中输入的值
    //callback 回调函数,将处理好的数据推回
    querySearchAsync(queryString, callback) {
      this.$nextTick(() => {
        var dto = {
          name: queryString,
          pageNumber: "1",
          pageSize: "99999"
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let arr = []
            res.forEach(item => {
              arr.push({
                value: item.name + "（" + item.landlordName + "）",
                label: item.id
              })
            })
            callback({ queryString: queryString, result: arr });
          })
      })
    },
    //获取小区列表
    getApartmentList(roleIds) {
      let _this = this;
      // _this.isLoading = true;
      if (roleIds == '3') {
        return;
      }
      var dto = {
        pageNumber: "1",
        pageSize: "99999"
      };
      return new Promise((resolve, reject) => {
        this.post("landlord-service/apartment/queryApartmentInfo", dto).then(
          res => {
            this.opt.form.forEach(data => {
              //工程人员授权弹框 公安账号授权一样的接口
              if (data.key === "apartmentIds") {
                let arr = [{
                  label: "全部",
                  value: "0"
                }]
                res.forEach(item => {
                  item.label = item.name + "（" + item.landlordName + "）";
                  item.value = item.id;
                });
                if (this.userId) {
                  let params = {
                    userId: this.userId
                  }
                  if (this.allFlag) {
                    params = {
                      apartmentId: res[0].id,
                      type: 'all'
                    }
                  }
                  this.post("/landlord-service/room/querySelectedHomeInfo", params).then(
                    result => {
                      let checkedApartment = [];
                      // _this.isLoading = false;
                      res.sort(_this.objArrCompare('id')).forEach((item, index) => {
                        result.sort(_this.objArrCompare('id')).forEach(it => {
                          if (item.id == it.id) {
                            checkedApartment.push(it.id)
                          }
                        })
                        data.opt.list = arr.concat(res);
                        this.apartmentIdsArr = checkedApartment;
                        resolve(res);
                      })
                    })
                } else {
                  // _this.isLoading = false;
                  data.opt.list = arr.concat(res);
                  resolve(res);
                }
              }
            });
          }
        );
      });
    },
    getRole() {
      let _this = this;
      let dto = {
        roleType: "1"
      }
      // 工程授权角色账号登录 需新增的入参 roleCode
      let roleCodeVal = null;
      if(_this.cache.getLS("userInfo")) {
        if(_this.cache.getLS("userInfo").roleCodes) {
          roleCodeVal = _this.cache.getLS("userInfo").roleCodes[0]
        }
      }
      if(roleCodeVal == 'project_authorization') {
        dto.roleCode = 'engineer';
      } 
      return this.post(
        "user-service/apartmentuser/selectRoleList", dto).then(res => {
        res = JSON.parse(JSON.stringify(res).replace(/roleName/g, "label"));
        res = JSON.parse(JSON.stringify(res).replace(/roleId/g, "value"));
        res.forEach((item, index) => {
          if (item.value == "2") {
            res.splice(index, 1);
          }
        });
        //roleIds
        _this.opt.form.forEach(item => {
          if (item.key == 'roleIds') {
            item.opt.list = res;
          }
        })
        // _this.opt.form[3].opt.list = res;
        return res;
      });
    },
    getAlarmList() {
      let _this = this;
      var dto = {
        pageNumber: "1",
        pageSize: "99999"
      };
      return new Promise((resolve, reject) => {
        // 请求报警器列表
        this.post("device-service/device/l2-alarm/page/query", dto).then(
          res => {
            this.opt.form.forEach(data => {
              if (data.key === "alarmMacs") {
                let arr = [];
                res.forEach(item => {
                  item.label = item.mac + (item.location ? ("（" + item.location + "）") : '');
                  item.value = item.mac;
                });
                data.opt.list = arr.concat(res);
                resolve(res);
              }
            });
          }
        );
      });
    },
    queryStoreByName(queryString, callback) {
      this.$nextTick(() => {
        var dto = {
          name: queryString,
          pageNumber: "1",
          pageSize: "99999"
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let arr = []
            res.forEach(item => {
              arr.push({
                value: item.name + "（" + item.landlordName + "）",
                label: item.id
              })
            })
            callback({ queryString: queryString, result: arr });
          })
      })
    },
    queryStores(name, city) {
      return cabinetUtil.queryStores(this.userId, name, city)
    },
    queryAllStores() {
      return cabinetUtil.queryStores()
    },
    queryCabinetListById(id) {
      return cabinetUtil.queryCabinetListById(id)
    },
    requstCounterAuth(form, userId) {
      let dto = {
        userId: userId,
      }
      if (!form.submitSelectData) {
        return Promise.resolve()
        //没有授权货柜数据
      } else if (form.submitSelectData.length == 1 && form.submitSelectData[0] == '0') {
        //全选
        dto.isAll = 1
      } else {
        dto.isAll = 0
        dto.counterList = form.submitSelectData
      }
      return cabinetUtil.requstCounterAuth(dto)
    },
    requstGatewayAuth(form, userId) {
       if (!form.selectedGateways || !form.selectedGateways.length) {
        return Promise.resolve()
        //没有授权货柜数据
      } else if(form.selectedGateways.length > 0) {
        let dto = {
          userId: userId,
        }
        dto.houseAuthDtoList = form.selectedGateways;
        dto.houseAuthDtoList.forEach(item => {
          delete item.gatewayList;
          delete item.isAuth;
        })
        return cabinetUtil.requstGatewayAuth(dto)
      }
    },
    queryHouses() {
        return cabinetUtil.queryHouses();
    },
    queryGateways(item) {
      return cabinetUtil.queryGatewayById(item,this.userId);
    },
    getSelected(){
      return new Promise((resolve, reject) => {
        this.post('mall-service/v1/get-counter-auth?userId=' + this.$route.params.id, {
            isUseResponse: true,
        }).then(res => {
          let selectData = []
          if(res){
            res.forEach(item => {
              selectData.push(item.counterName)
            })
          }else{
            selectData = []
          }
          resolve(res)
        }).catch(err => {

        })
      })
        
    },
    addOrUpdateSystemUser(form, dtoApartmentIds) {
      let _this = this
      let dto = {
        username: form.username,
        realname: form.realname ? form.realname : "",
        workNo: form.workNo,//以前的工号字段改成手机号
        userstate: form.userstate ? "2" : "1",
        isCounter: form.isCounter ? "1" : "0",
        gatewayPermit: form.gatewayPermit ? "1" : "0",
        roleIds: [form.roleIds],
        userApartmentList: form.roleIds == '3' ? form.selectedRoom : dtoApartmentIds,
        // apartmentIds: dtoApartmentIds,
        // phonenum: form.phonenum,
        endUser: form.endUser,
        relieveBindCounter: form.unBindCounter ? 1 : 0,
        projectPermit: form.projectPermit ? 1 :0,
        // authorizedApp: form.authorizedApp,
      };
      if (form.roleIds == '9') {
        dto.l2dAlarmList = form.alarmMacs;
      }
      let url = "user-service/user/addOrUpdateSystemUser";
      let message = "添加成功";
      let operateName = _this.$route.params.type == 'add' ? '添加用户' : '编辑用户';
      if (form.userId) {
        dto.userId = form.userId;
        message = "编辑成功";
        if (form.username == form.password) {
          _this.$message({
            message: "账号和密码不能相同",
            type: "warning"
          });
          return false;
        }
      }
      if (_this.oldPassword != form.password) {
        dto.password = form.password;
      } else {
        dto.password = "";
      }

      let successFunc = () => {
        _this.$message({
          showClose: true,
          message: message,
          type: "success"
        })
      }

      _this.post(url, dto, {
        isUseResponse: true,
        operatorFlage: true,
        operateName: operateName,
      }).then(async res => {
        //新增/编辑工程人员成功后->提交货柜授权
        if (form.isCounter) {
          let myUserId = form.userId ? form.userId : res.data.data
          await _this.requstCounterAuth(form, myUserId)
        } 
        if (form.gatewayPermit) {
          let myUserId = form.userId ? form.userId : res.data.data
          await _this.requstGatewayAuth(form, myUserId)
        }
        successFunc()
        _this.$back();
      })
    }
  }
};
</script>
<style scoped lang='scss'>
</style>