var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-detail" },
    [
      _c("app-detail", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        ref: "detail",
        attrs: { opt: _vm.opt }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }